import { computed, nextTick, ref, watch } from 'vue';

import { defineStore } from 'pinia';

import router from '@/router';
import Keycloak from 'keycloak-js';

import { z } from 'zod';

import i18n from '@/i18n';

import { AuthService } from '@/services/auth.service';
import { SamleikinService } from '@/services/samleikin.service';
import { UserService } from '@/services/user.service';

import { LoginSchema } from '@/schemas/login.schema';

import { User } from '@/models/user.model';

export const useAuthStore = defineStore('authStore', () => {
    const user = ref<User>();
    const accessToken = ref<string>();
    const isFetched = ref(false);
    const isUserVerified = ref(false);
    const keycloak = ref<Keycloak>();

    const importedUserEmail = ref<string>();

    return {
        user,
        accessToken,
        isUserVerified,
        keycloak,
        importedUserEmail,

        // Computed

        id: computed(() => user.value?.id || ''),

        // Functions

        async me() {
            const { response, error } = await UserService.me();

            if (error) {
                isFetched.value = true;

                return;
            }

            user.value = response.data;

            isUserVerified.value = user.value.is_email_verified;

            isFetched.value = true;

            return user.value;
        },

        async login(data: z.infer<typeof LoginSchema>) {
            const { response, error } = await AuthService.login({
                ...data,
            });

            if (error) {
                return { response, error };
            }

            accessToken.value = response.data.token;

            localStorage.setItem('økisnavn_access_token', accessToken.value);

            const user = await this.me();

            if (user?.language) {
                i18n.global.locale.value = user.language as 'en' | 'fo';

                localStorage.setItem('økisnavn_locale', user.language);
            } else {
                const locale = i18n.global.locale.value;

                await UserService.updateLanguage(locale);
            }

            return {
                response,
                error,
            };
        },

        async handleSamleikinUser(data: Keycloak) {
            if (!data.token) {
                return;
            }

            const { response, error } = await SamleikinService.checkUser(data.token);

            if (error) {
                Toast.add({ message: error.response?.data.message as string, type: 'error' });

                this.logout();
                return;
            }

            if (!response.data.user_exists) {
                if (data.tokenParsed?.exp) {
                    const date = new Date(data.tokenParsed.exp * 1000);

                    localStorage.setItem(
                        'økisnavn_create_user_data',
                        JSON.stringify({
                            token: data.token,
                            name: data.tokenParsed?.name,
                            expires: date.toString(),
                        }),
                    );
                }

                return;
            }

            const hasToken = localStorage.getItem('økisnavn_access_token');

            accessToken.value = data.token;

            localStorage.setItem('økisnavn_access_token', accessToken.value);

            this.loginSamleikin(!!hasToken);
        },

        async loginSamleikin(isLoggedIn: boolean) {
            const user = await this.me();

            if (user?.language) {
                i18n.global.locale.value = user.language as 'en' | 'fo';

                localStorage.setItem('økisnavn_locale', user.language);
            } else {
                const locale = i18n.global.locale.value;

                await UserService.updateLanguage(locale);
            }

            if (!isLoggedIn) {
                nextTick(() => {
                    router.push({ name: 'domains' });
                });
            }
        },

        async createUser(token: string) {
            accessToken.value = token;

            localStorage.setItem('økisnavn_access_token', accessToken.value);

            await this.loginSamleikin(false);
        },

        async getAccessToken() {
            if (!accessToken.value) {
                const token = localStorage.getItem('økisnavn_access_token');

                if (token) {
                    accessToken.value = token;
                }
            }

            return accessToken.value;
        },

        async logout() {
            await AuthService.logout();
        },

        async waitUntilFetched() {
            let unwatch = () => {};

            return await new Promise((resolve) => {
                unwatch = watch(
                    isFetched,
                    (isFetched) => {
                        if (isFetched) {
                            unwatch();

                            resolve(null);
                        }
                    },
                    {
                        immediate: true,
                    },
                );
            });
        },
    };
});
